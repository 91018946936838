@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@200;400;600;800');

$primary: #B83D56;
$dark: #752736;
$light: #DB4866;
$darker: #361219;
$lighter: #F55172;
$white: #fff6f6;

$font-title: 'Noto Sans Mono', monospace;

.container {
	margin: auto;
}

* {
	box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $font-title;
}

body {
	background-color: $white;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

	nav {
		color: $white;
		font-size: 1.1rem;
		font-family: $font-title;
		margin: 0;
		position: fixed;
		z-index: 200;
		background-color: $dark;
		width: 100%;
		min-height: calc(40px + 2rem);

		.show-nav + button.navbar__burger .burger-bar{
			background-color: transparent;
			width: 0;
			
			&::before {
				transform: rotate(45deg);
			}

			&::after {
				transform: rotate(-45deg);
			}
		}

		button.navbar__burger {
			position: absolute;
			top: 1rem; 
			right: 1rem;
			width: 40px;
			height: 40px;
			background-color: transparent;
			color: inherit;
			outline: none;
			border: none;
			cursor: pointer;

			.burger-bar {
				&,
				&::before,
				&::after {
					display: block;
					background-color: $white;
					width: 40px;
					height: 3px;
					position: absolute;
					left: 0;
					transition: all .2s ease-in-out;
				}

				&::before,
				&::after {
					content: '';
				}

				&::before {
					transform: translateY(-12px);
				}

				&::after {
					transform: translateY(12px);
				}
			}
		}

		ul.navbar {
			justify-content: center;
			flex-direction: column;
			align-items: center;
			flex-wrap: wrap;
			list-style: none;
			display: flex;
			padding-left: 0;
			margin-top: 0;
			margin-bottom: 0;
			position: fixed;
			top: 0;
			bottom: 0;
			right: -100vw;
			background-color: $dark;
			width: 0;
			transition: all .8s ease-out;

			&.show-nav {
				width: 100%;
				right: 0;
			}
			
			li.navbar__item {
				margin: .5rem;
				padding: .5rem;
				text-align: center;

				&:not(:last-child)::after {
					content: "";
					display: block;
					height: 1px;
					width: 10vw;
					background-color: $white;
					margin: 1rem auto 0;
					transform: translateY(.5rem);
				}

				a.navbar__link {
					text-decoration: none;
					color: inherit;
					font-weight: 600;
					box-sizing: border-box;
					cursor: pointer;
					transition: all .2s ease;
				}
			}
		}
	}

	section#products {
		background-image: linear-gradient(-65deg, $light 0 15%, $primary 15% 100%);
		color: $white;
		padding: 4rem 0 8rem;
		font-size: 1.2rem;

		header.introduction {
			h1 {
				text-align: center;
			}

			p {
				text-align: center;
				margin: auto;
				width: 75%;
			}
		}
		
		.products-list {
			margin-top: 6rem;
			display: flex;
			justify-content: space-evenly;
			flex-wrap: wrap;
			gap: 4rem;
		}

		article.product-item {
			text-align: center;
			width: 80%;

			h4 {
				margin: 1rem 0;
			}

			div.img {
				width: 100px;
				height: 100px;
				margin: auto;
				position: relative;
				margin-bottom: 3rem;

				.img-wrapper {
					background-color: white;
					border-radius: 100%;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 100;
					
					img {
						border-radius: 100%;
						width: 70%;
						position: absolute;
						z-index: 100;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}
				
				
				&::before, &::after {
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					border-radius: 100%;
					position: absolute;
				}

				&::before {
					background-color: $lighter;
					transform: translate(.4rem, .4rem);
				}

				&::after {
					background-color: $darker;
					transform: translate(-.4rem, -.4rem);
				}
			}
		}
	}

	section#projects {
		padding: 4rem 0 8rem;
		font-size: 1.2rem;
		
		header.introduction {
			h1 {
				text-align: center;
			}

			p {
				text-align: center;
				margin: auto;
				width: 75%;
			}
		}

		.projects-list {
			margin-top: 3rem;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			gap: 1rem;
		}

		article.project-item {
			padding: 2rem;
			background-color: $white;
			box-shadow: 0 0 2rem rgba($primary, .03);
			border: solid 1px rgba($primary, .2);
			border-radius: 1rem;
			width: 100%;

			.tags {
				display: flex;
				flex-wrap: wrap;
				width: 100%;

				span.project-tag {
					color: $light;
					font-size: medium;
					font-weight: bold;
					margin: 0 .2rem;
				}
			}

			h1 {
				font-size: larger;
				margin-top: .2rem;
			}

			.project-links-list {
				.project-link {
					display: inline-block;
					margin: 0 .5rem;

					&:first-child {
						margin-left: 0;
					}

					&::after {
						content: '';
						margin-top: .2rem;
						display: block;
						border-bottom: solid 2px $primary;
						transform: scaleX(0);
						transform-origin: center;
						transition: all ease-in-out .2s;
					}

					&:hover::after {
						transform: scaleX(1);
						border-bottom-color: $light;
					}

					& > a {
						font-weight: bold;
						text-decoration: none;
						padding-bottom: .2rem;
						color: $primary;
						transition: color ease .2s;

						&::after {
							content: '➔';
							display: inline;
							margin-left: .2rem;
						}

						&:hover {
							color: $light;
						}
					}
				}	
			} 
		}
	}

	main#about {
		padding: calc(2rem + 75px) 0 5rem;

		.container {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 200px;
		}

		strong {
			color: $light;
			font-weight: 700;
		}

		em {
			font-style: normal;
			color: $dark;
			font-weight: 600;
		}

		.avatar {
			text-align: center;
			position: relative;
			margin-top: 4rem;
			display: inline-block;
			transform: translateX(-100px);

			&::before, &::after {
				content: '';
				z-index: -1;
				display: block;
				width: 200px;
				height: 200px;
				border-radius: 100%;
				position: absolute;
			}

			&::before {
				background-color: $light;
				transform: translate(.6rem, .6rem);
			}

			&::after {
				background-color: $dark;
				transform: translate(-.6rem, -.6rem)
			}

			& > img {
				border-radius: 100%;
				position: absolute;
				top: 0;
				left: 0;
				width: 200px;
				height: 200px;
			}
		}

		#description {
			text-align: center;
			font-size: x-large;
			padding: 0 2rem;
			width: 90%;
			margin: auto;
		}
	}

	footer#contact {
		background-image: linear-gradient(45deg, $dark 0 20%, $darker 20% 100%);
		padding: 3rem 0 8rem;
		color: white;
		text-align: center;
		font-size: 1.3rem;

		a {
			text-decoration: none;
			color: white;
			font-weight: 600;

			&:hover {
				text-decoration: underline;
			}
		}

		.socials {
			margin-top: 1rem;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			gap: 1rem;
			width: 100%;
			margin: auto;

			.socials-item {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				.img-container {
					display: flex;
					justify-content: center;
					height: 100px;

					img {
						width: 100px;
					}
				}

				p {
					justify-self: flex-end;
				}
			}
		}
	}

	footer {
		padding: 2rem;
		text-align: center;
	}
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
	.container {
		max-width: 540px;
	}

	body main#about {
		padding: 15rem 0;
	}
	
	body nav {
		min-height: auto; 

		ul.navbar {
			flex-direction: row;
			justify-content: flex-end;
			position: relative;
			width: 100%;
			right: 0;
			transition: none;

			li.navbar__item {
				&:not(:last-child)::after {
					display: none;
				}

				a.navbar__link {
					&:hover::after{
						transform: scaleX(1);
					}

					&::after {
						content: '';
						margin-top: .3rem;
						display: block;
						height: 3px;
						width: 100%;
						background-color: $white;
						transform: scaleX(0);
						transition: transform .2s ease-in-out;
					}
				}
			}
		}

		button.navbar__burger {
			display: none;
		}
	}

	#projects {
		.project-item {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
	}

	#about {
		#description {
			.content {
				padding: 0 1rem;
			}
		}
	}

	body footer#contact {
		.socials {
			width: 70%;
			flex-direction: row;
			margin-top: 2rem;
			gap: 1rem;
		}
	}
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
	.container {
		max-width: 720px;
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
	.container {
		max-width: 960px;
	}
	
	#products {
		.product-item {
			width: calc(100% / 3 - 4rem) !important;
		}
	}

	#projects {
		.project-item {
			width: calc(100% / 2 - 1rem) !important;
		}
	}

	#about {
		#description {
			.content {
				width: calc(100% - 200px - 2rem);
			}
		}
	}
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
	.container {
		max-width: 1140px;
	}
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { 
	.container {
		max-width: 1320px;
	}
	
	#projects {
		.project-item {
			width: calc(100% / 3 - 1rem) !important;
		}
	}
}